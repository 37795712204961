'use client';

import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { httpsCallable } from 'firebase/functions';
import { functions, firestore } from "@/lib/firestore";
import { useRouter } from 'next/navigation';
import { useTranslations } from "next-intl";
import { getLangDir } from "rtl-detect";
import { useLocale } from "use-intl";
import { doc, getDoc } from 'firebase/firestore';

const schema = z.object({
  videoId: z.string().regex(/^\d+$/, { message: "Video ID must be numeric" })
});

type FormData = z.infer<typeof schema>;

export default function TwitchImportForm() {
  const locale = useLocale();
  const t = useTranslations();
  const direction = getLangDir(locale);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const router = useRouter();

  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm<FormData>({
    resolver: zodResolver(schema)
  });

  const videoId = watch('videoId');

  useEffect(() => {
    const checkExistingAnalysis = async () => {
      if (videoId) {
        const docRef = doc(firestore, 'twitch_import_queue', videoId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          router.push(`/${locale}/twitch/videos/${videoId}/progress`);
        }
      }
    };

    checkExistingAnalysis();
  }, [videoId, router, locale]);

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    setError('');
    try {
      const registerTwitchImportQueue = httpsCallable(functions, 'registerTwitchImportQueue');
      await registerTwitchImportQueue({ videoId: data.videoId });
      router.push(`/${locale}/twitch/videos/${data.videoId}/progress`);
    } catch (error: any) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const match = value.match(/(?:https?:\/\/)?(?:www\.)?twitch\.tv\/videos\/(\d+)/);
    if (match) {
      setValue('videoId', match[1]);
    } else {
      setValue('videoId', value);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-4" dir={direction}>
      <div className="flex">
        <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
          Twitch https://twitch.tv/
        </span>
        <input
          {...register('videoId')}
          type="text"
          placeholder="123345678"
          className="rounded-none rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
          onChange={handleInputChange}
        />
      </div>
      {errors.videoId && <p className="text-red-500">{errors.videoId.message}</p>}
      <button
        type="submit"
        disabled={isLoading}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isLoading ? t('解析中...') : t('解析する')}
      </button>
      {error && <p className="text-red-500">{error}</p>}
    </form>
  );
}