"use client"

import React, { createContext, useContext, useState } from 'react';

interface DisplaySettingModalContextType {
  isModalVisible: boolean;
  showModal: () => void;
  hideModal: () => void;
}

const DisplaySettingModalContext = createContext<DisplaySettingModalContextType | undefined>(undefined);

export const DisplaySettingModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  return (
    <DisplaySettingModalContext.Provider value={{ isModalVisible, showModal, hideModal }}>
      {children}
    </DisplaySettingModalContext.Provider>
  );
};

export const useDisplaySettingModal = () => {
  const context = useContext(DisplaySettingModalContext);
  if (context === undefined) {
    throw new Error('useDisplaySettingModal must be used within a DisplaySettingModalProvider');
  }
  return context;
};