"use client"

import "../../app/globals.css";
import Link from "next/link";
import NavBar from "@/components/navBar";
import { useTranslations } from "next-intl";
import { getLangDir } from "rtl-detect";
import { useLocale } from "use-intl";
import { DisplaySettingModalProvider } from "@/context/displaySettingModalContext";

export default function Layout({
  children,
  footerSlot,
}: Readonly<{
  children: React.ReactNode;
  footerSlot?: React.ReactNode;
}>) {
  const t = useTranslations();
  const locale = useLocale();
  const direction = getLangDir(locale);

  return (
    <DisplaySettingModalProvider>
      <div dir={direction} className="flex flex-col min-h-screen">
        <header className="bg-gray-800 text-white p-4">
          <Link href="/" className="flex items-center">
            <svg
              className="w-8 h-8 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            <h1 className="text-xl font-bold">{t('Live Analyzer')}</h1>
          </Link>
        </header>

        <main className="flex-grow overflow-auto pb-16">
          {children}
        </main>

        <footer className="fixed bottom-0 left-0 right-0">
          {footerSlot}
          <NavBar />
        </footer>
      </div>
    </DisplaySettingModalProvider>
  );
}
