// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {connectFunctionsEmulator, getFunctions, httpsCallable} from "firebase/functions";
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";
import {getAnalytics, isSupported} from "@firebase/analytics";
import {connectStorageEmulator, getStorage} from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBAZLErOkR2Is5Ak7PfqgeJ43HQZUSuymw",
    authDomain: "live-analyzer-aceee.firebaseapp.com",
    projectId: "live-analyzer-aceee",
    storageBucket: "live-analyzer-aceee.firebasestorage.app",
    messagingSenderId: "582841019788",
    appId: "1:582841019788:web:2891b009cbd9b4da867aa9",
    measurementId: "G-9YKLX90S7Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);

export async function initializeAnalytics() {
  if (await isSupported()) {
    const analytics = getAnalytics(app);
    return analytics;
  }
  return null; // サポートされない場合はnullを返す
}

if (process.env.NODE_ENV === 'development') {
    connectFirestoreEmulator(firestore, 'localhost', 8085)
    connectFunctionsEmulator(functions, 'localhost', 5005)
    connectStorageEmulator(storage, 'localhost', 9199)
}
