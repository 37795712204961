"use client";

import React, { useRef, useEffect } from 'react';
import { useTranslations } from 'next-intl';
import { FacebookShareButton, TwitterShareButton, LineShareButton, WhatsappShareButton, TelegramShareButton, WeiboShareButton, VKShareButton } from 'react-share';
import { SocialIcon } from 'react-social-icons';
import { FaCopy, FaWeibo } from 'react-icons/fa';

interface ShareProps {
  isVisible: boolean;
  onClose: () => void;
}

const Share: React.FC<ShareProps> = ({ isVisible, onClose }) => {
  const t = useTranslations();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, onClose]);

  const url = typeof window !== 'undefined' ? window.location.href : '';
  const text = typeof document !== 'undefined' ? document.title : '';

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    alert(t('URLをクリップボードにコピーしました'));
  };

  if (!isVisible) return null;

  const socialNetworks = [
    { network: 'facebook', Button: FacebookShareButton },
    { network: 'twitter', Button: TwitterShareButton },
    { network: 'line.me', Button: LineShareButton },
    { network: 'whatsapp', Button: WhatsappShareButton },
    { network: 'telegram', Button: TelegramShareButton },
    { network: 'weibo', Button: WeiboShareButton },
    { network: 'vk', Button: VKShareButton },
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div ref={modalRef} className="bg-white p-6 rounded-lg shadow-xl w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">{t('シェア')}</h2>
        <div className="flex flex-nowrap overflow-x-auto space-x-4 mb-4 pb-2">
          <button onClick={copyToClipboard} className="flex flex-col items-center">
            <FaCopy size={24} className="text-gray-700" />
            <span className="mt-1 text-xs">{t('コピー')}</span>
          </button>
          {socialNetworks.map(({ network, Button }) => (
            <Button key={network} url={url} title={text}>
              <div className="flex flex-col items-center">
                {network === 'weibo' ? (
                  <FaWeibo size={24} color="#D83232" />
                ) : (
                  <SocialIcon network={network} style={{ height: 24, width: 24 }} />
                )}
                <span className="mt-1 text-xs">{network}</span>
              </div>
            </Button>
          ))}
        </div>
        <button
          onClick={onClose}
          className="w-full mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          {t('閉じる')}
        </button>
      </div>
    </div>
  );
};

export default Share;
