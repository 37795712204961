"use client"

import React, { useState } from 'react';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import { FaChartBar, FaSearch, FaCog, FaShare } from 'react-icons/fa';
import { useDisplaySettingModal } from '@/context/displaySettingModalContext';
import Share from '@/components/share';

const NavBar: React.FC = () => {
  const t = useTranslations();
  const { showModal: showDisplaySettingModal } = useDisplaySettingModal();
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);

  return (
    <nav className="bg-gray-800 p-4">
      <div className="text-white container mx-auto flex justify-around items-center">
        <Link href="/" className="flex flex-col items-center">
          <FaChartBar className="text-2xl mb-1" />
          <span className="text-sm">{t('解析')}</span>
        </Link>
        <div className="flex flex-col items-center text-gray-500">
          <FaSearch className="text-2xl mb-1" />
          <span className="text-sm">{t('検索')}</span>
        </div>
        <button onClick={showDisplaySettingModal} className="flex flex-col items-center bg-transparent border-none">
          <FaCog className="text-2xl mb-1" />
          <span className="text-sm">{t('表示設定')}</span>
        </button>
        <button onClick={() => setIsShareModalVisible(true)} className="flex flex-col items-center bg-transparent border-none">
          <FaShare className="text-2xl mb-1" />
          <span className="text-sm">{t('共有')}</span>
        </button>
      </div>
      <Share isVisible={isShareModalVisible} onClose={() => setIsShareModalVisible(false)} />
    </nav>
  );
};

export default NavBar;
